<template>
  <div class="promotion">
    <header>
      <square src="https://img.xinshu.me/resource/fe6720b4e46945d8be1e432cabc2f1d7"
              plain height="65.68915%" radius="0" style="pointer-events: none"/>
    </header>

    <nav class="tabs">
      <div class="tab" v-for="(v, k) in tabs" :key="k"
           @click="tab = k" :class="{active: tab === k}">
        <fa icon="location-dot" fas/>
        {{v}}
      </div>
    </nav>

    <section>
      <b-row class="header">
        <b-col>
          <avatar size="30px" :src="user.avatar"/>
          <b>{{user.nickname}}</b>
        </b-col>
        <b-col cols="auto">
          <div class="rule" v-b-modal="'rules'">活动规则</div>
        </b-col>
      </b-row>

      <div class="content text-center" v-if="totalCount === -1">
        加载中...
      </div>

      <div class="content text-center" v-else>
        <template v-if="tab === 'lottos'">
          <div class="board-container">
            <div class="board"
                 :style="{transform: `rotate(${wheelDeg}deg)`, transitionDuration: `${animationDuration}ms`}"
                 :class="{rolling}">
              <div class="board-inner">
                <div class="prize" v-for="(i, index) in prizeList"
                     :style="{transform: `rotate(${index * 45}deg)`}"
                     :key="i.tid">
                  <div class="inner">
                    <div class="title">{{i.name}}</div>
                    <div>
                      <square :src="i.icon" plain mode="contain"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="trigger" v-if="remainCount <= 0">
              <div class="text" v-if="remainCount === 0">无法抽奖</div>
            </div>
            <div class="trigger" @click="onClickRotate" v-else>
              <div class="text">{{rolling ? '正在开奖' : '开始抽奖'}}</div>
              <img src="https://img.xinshu.me/resource/3e3390373d294a069f80f5ad1aae356f"
                   alt="">
            </div>
          </div>
          <footer class="mt-3">
            <b-btn size="lg" class="theme-yellow" @click="onClickRotate">
              剩余{{remainCount}}次
            </b-btn>

            <b-btn size="lg" class="theme-blue"
                   v-if="totalCount > remainCount + (rolling ? 1 : 0)"
                   v-b-modal="'prizes'">查看奖品</b-btn>
          </footer>

          <b-modal title="奖品列表" id="prizes" hide-footer @show="fetchCoupons">
            <empty v-if="!coupons.length" icon="medal">还没有获得任何奖品哦</empty>
            <template v-else>
              <p>已获得 {{coupons.length}} 个奖品</p>
              <coupon :value="item" v-for="item in coupons" :key="item.id"/>
            </template>
          </b-modal>

          <b-modal title="抽奖结果" id="prize" hide-footer>
            <div class="text-center">
              <h4>恭喜您，中奖啦</h4>
              <div>
                已获得
                <span style="color: #DD483F; font-size: 1.5em">{{prize.name}}</span>
                优惠券
              </div>
              <div>
                <square mode="contain" :src="prize.icon" style="max-width: 60%;" class="mx-auto"/>
              </div>
            </div>
          </b-modal>
        </template>

        <div class="tab-content" v-if="tab === 'referral'">
          <div class="mb-3">
            <square :src="poster" class="mx-auto" @click="$previewImage(poster)"
                    border="2px solid #000"
                    height="177.866667%" plain radius="0" style="max-width: 50%;"/>
          </div>
          <h4>
            送券给新人好友
            <br>
            下单还能得
            <span @click="debug = true">20%</span>
            返佣
          </h4>
          <hr>
          <div>
            分享专属邀请海报<br>
            好友扫码下单您可获得返现<br>
            <b-link to="/my/referral/revenue?type=balance">查看返现收益</b-link>
          </div>
          <footer class="mt-3">
            <b-btn size="lg" class="theme-blue" @click="savePoster" :disabled="!poster">
              {{isMobile ? '长按图片' : '点击'}}保存海报
            </b-btn>
          </footer>
        </div>

        <div class="tab-content" v-if="tab === 'coupon'">
          <div class="mb-3">
            <square src="https://img.xinshu.me/resource/47138b84d6514f1ab3654c86df25080d"
                    height="78.764479%" plain radius="0"/>
          </div>
          <h4>微信书首单限时6折<br>史无前例平台补贴</h4>
          <hr>
          <div class="text-theme-red" v-if="orderCount > 0">您已做过微信书<br>机会留给新朋友吧~</div>
          <div v-else>
            点击下方按钮领取<br>
            (*仅限从未购买过微信书的用户)
          </div>
          <footer class="mt-3">
            <b-btn size="lg" class="theme-blue" @click="getCoupon" v-if="couponNo">点击领取</b-btn>
            <template v-else-if="orderCount > 0">
              <b-btn size="lg" class="theme-blue" @click="tab = 'referral'">看看更多优惠活动</b-btn>
            </template>
            <b-btn size="lg" class="theme-blue" disabled v-else>已领取</b-btn>
          </footer>
        </div>

        <div class="tab-content" v-if="tab === 'collection'">
          <div class="mb-3">
            <square src="https://img.xinshu.me/resource/339797f1537a4441a81f10a9d32466b0"
                    height="82.926829%" plain radius="0"/>
          </div>
          <h4>这些年<br>你一共做了多少本微信书</h4>
          <hr>
          <div>
            晒出你的「微信书全家福」
            <br>
            每本送 5 元，最高可送 200 元
          </div>
          <footer class="mt-3">
            <b-btn size="lg" class="theme-blue" to="/promotions/collection-2022">点击前往</b-btn>
          </footer>
        </div>
      </div>

      <b-modal id="rules" title="活动规则" hide-footer size="sm">
        <div class="my-2" v-for="i in rules" :key="i">{{i}}</div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import { wait } from '@/utils/wait-for'
import Coupon from '@/components/Coupon'

export default {
  name: 'eighthAnniversary',
  components: {Coupon},
  mixins: [require('@/mixins/preview-image').default],
  title: '心书8周年',
  data() {
    return {
      tab: '',
      ticket: '',
      totalCount: -1,
      remainCount: -1,
      orderCount: -1,
      prize: {},
      couponNo: '',
      coupons: [],
      debug: false,
      rolling: false,
      animationDuration: 4500,
      wheelDeg: 0,
      prizeNumber: 8,
      allRules: {
        lottos: [
          '所有用户可根据订单数量进行抽奖',
          '1. 所有用户可免费抽奖1次',
          '2. 每在心书下单1次，可额外获得1次抽奖次数，最多可获得10次',
          '3. 奖品以优惠券的形式发放，有效期为14天，请及时使用',
          '4. 活动时间：3月17日—3月31日'
        ],
        coupon: [
          '微信书（朋友圈单品）首单享6折体验价',
          '1. 适用产品：仅面向微信书（朋友圈单品），心书平台其他产品不参与本折扣',
          '2. 订单条件：从未买过微信书（朋友圈单品），在本次活动期间购买该产品的首个订单（从未购买过“微信书”单品的老用户首单，也可享受）',
          '3. 优惠券的有效期为14天，请及时使用，避免过期',
          '4. 活动时间：3月17日—3月31日'
        ],
        collection: [
          '拍「微信书全家福」照片，发到朋友圈集赞，截图给我们就可以获得微信书本数×5元的做书无门槛优惠券',
          '1. 请你找出所有做过的微信书，拍摄至少1张「微信书全家福」',
          '2. 将「微信书全家福」发送到自己的微信朋友圈，并配上我们为您提供的专属文案',
          '3. 将发送的朋友圈截图，并配上「微信书全家福」，工作人员将会在7个工作日内向您发放相应做书无门槛优惠券（每本送5元，最高送200元）'
        ],
        referral: [
          '通过专属海报邀请好友获得返现',
          '1. 好友可获得100元新人优惠券',
          '2. 新人好友下单，你可以得到20%的返佣，返佣直接发放到心书账户余额，以后做书可用（*新人指从未关注过心书公众号的用户）',
          '3. 活动结束后仍然可获得返现，但比例降为10%，可申请提高至20%',
          '4. 活动时间：3月17日—3月31日'
        ]
      },
      prizeListOrigin: [
        {
          tid: 'b5album',
          icon: 'https://img.xinshu.me/upload/bdeca7afb6914371a6f59b25f18ad0b1',
          name: '锁线精装照片书'
        },
        {
          tid: 'xalbum',
          icon: 'https://img.xinshu.me/upload/ad06f373e8f545a4968512acacfe7004',
          name: 'A4精装纪念册'
        },
        {
          tid: 'postcard',
          icon: 'https://img.xinshu.me/upload/ecb8d5df6b184c8ca43e3cfccf4df40a',
          name: 'LOMO卡'
        },
        {
          tid: 'xcalbum',
          icon: 'https://img.xinshu.me/upload/0f5ee965a655491f863ca9e88a3df3d2',
          name: 'A4轻奢杂志册'
        },
        {
          tid: 'frame-moment',
          icon: 'https://img.xinshu.me/upload/000f6e601546465e8ca6a6b501e2a373',
          name: '朋友圈相框'
        },
        {
          tid: 'calendar-2022',
          icon: 'https://img.xinshu.me/resource/9c074588f2644dbeadc0eae248a0f0ad',
          name: '2022经典台历'
        },
        {
          tid: 'note',
          icon: 'https://img.xinshu.me/upload/1b97f57e1181425aaa5b4667df39b192',
          name: '亲子笔记本'
        },
        {
          tid: '',
          icon: 'https://img.xinshu.me/upload/d3ba25bfd1fb4e999ae811a12f406e71',
          name: '谢谢惠顾'
        }
      ]
    }
  },
  computed: {
    rules() {
      return this.allRules[this.tab]
    },
    tabs() {
      if (this.debug) {
        return {
          lottos: '抽奖',
          referral: '代言人',
          collection: '晒书',
          coupon: '领券'
        }
      }
      if (this.orderCount === 0) {
        return {
          lottos: '幸运大抽奖',
          coupon: '首单享优惠',
          referral: '邀请送好礼'
        }
      }
      return {
        lottos: '幸运大抽奖',
        referral: '邀请送好礼',
        collection: '晒书全家福'
        // coupon: '新用户折扣'
      }
    },
    prizeList() {
      return this.prizeListOrigin.slice(0, this.prizeNumber)
    },
    poster() {
      if (!this.ticket) {
        return ''
      }
      return 'https://canvas.xinshu.me/generate/personal-8year' + this.serialize({
        avatar: this.user.avatar,
        nickname: this.user.nickname,
        ticket: this.ticket
      })
    }
  },
  watch: {
    tab(val) {
      sessionStorage.setItem('8th.tab', val)
      this.wheelDeg = 0
    }
  },
  beforeDestroy() {
    this.close()
  },
  async mounted() {
    this.getLottos()
    this.tab = this.$route.query.tab || sessionStorage.getItem('8th.tab') ||
      Object.keys(this.tabs)[0]
    const result = await this.$ajax.fetchUserProfile()
    this.ticket = result.qrTicket
  },
  methods: {
    close() {
      this.showModal = false
      document.body.classList.remove('modal-open')
    },
    async getLottos() {
      const result = await this.$req.get('/api/eighth_anniversary/lottos')
      this.remainCount = Number(result.remainLottosCount) || 0
      // this.remainCount = 10
      this.totalCount = Number(result.totalLottosCount) || 0
      this.orderCount = Number(result.wxbookOrderCount) || 0
      this.couponNo = result.wxbookActivityCouponNo
    },
    async onClickRotate() {
      if (this.remainCount <= 0) {
        this.$alert.warn('抽奖次数已经用完啦')
        return
      }

      if (this.rolling) {
        return
      }

      this.rolling = true
      const result = await this.$req.post('/api/eighth_anniversary/lottos')

      if (!result) {
        this.$alert.error('啊偶，抽奖出了些问题哦，请重新试试吧')
        return
      }

      const {wheelDeg, prizeList} = this
      const index = prizeList.findIndex(i => result.tid === i.tid)

      this.prize = prizeList[index]
      this.wheelDeg += -wheelDeg % 360 + 6 * 360 +
        (360 - 360 / prizeList.length * index) - 22.5 +
        (15 - Math.random() * 30) // +-15度的偏移

      await wait(this.animationDuration)
      this.rolling = false
      this.remainCount = result.remainLottosCount

      if (result.tid) {
        this.$bvModal.show('prize')
      }
    },
    async fetchCoupons() {
      const couponNos = ['PUBFOG', '1P4TXG', 'GIXJ4U', 'GM4IUZ', '0G6OQA', 'HBTY21', 'NPZWCF']
      const results = await this.$ajax.fetchCoupons()
      this.coupons = results.filter(i => couponNos.includes(i.activity?.couponNo))
    },
    async getCoupon() {
      await this.$req.get('/api/user/activity_coupons/' + this.couponNo)
      this.$alert.success('优惠券领取成功')
      this.couponNo = ''
      this.$router.push('/my/wallet')
    },
    savePoster() {
      if (this.isMobile) {
        this.$alert.success('请长按上方图片保存哦')
      } else {
        window.open(this.poster + '&dl=1')
      }
    }
  }
}
</script>

<style lang="scss">
body[data-page="8thAnniversary"] {
  background-color: #F0E1E3;
  background-image: none;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: #FC7B5F;
    z-index: -1;
  }

  main {
    padding-bottom: 0;
  }
}
</style>

<style scoped lang="scss">
.promotion {
  $darkColor: #332535;
  $theme-red: #FC7B5F;
  $theme-yellow: #FFBE56;
  $theme-pink: #F0E1E3;
  $theme-blue: #004FFF;

  $theme-border: 2px solid #000;

  display: flex;
  flex-direction: column;
  max-width: 480px;
  padding: 0 $grid-gutter-width / 2;
  user-select: none;
  overflow-x: hidden;
  min-height: 100vh;

  header {
    border-left: $theme-border;
    border-right: $theme-border;
    padding: 10px;
    padding-right: 0;
    background-color: $theme-pink;

    .square {
      position: relative;
      right: -2px;
    }
  }

  .avatar {
    border-radius: 0;
    border: $theme-border;
    margin-right: 5px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }

  .tab {
    padding: 1rem 0;
    width: 100%;
    flex-basis: 100%;
    flex-grow: 1;
    background-color: $theme-red;
    border: $theme-border;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;

    + .tab {
      border-left: 0;
    }

    &:not(.active) i {
      display: none;
    }

    &.active {
      color: #000;
      background-color: $theme-pink;
    }
  }

  section {
    padding: 1rem;
    padding-bottom: 2rem;
    border-left: $theme-border;
    border-right: $theme-border;
    background-color: $theme-pink;
    flex: 1;
    overflow: hidden;

    .header {
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: 1rem;
    }

    .rule {
      border: $theme-border;
      padding: 4px 10px;
      padding-left: 14px;
      margin-right: -$grid-gutter-width / 2 - 2px;
      background-color: $theme-red;
    }
  }

  .board-container {
    position: relative;
  }

  .board {
    position: relative;
    border-radius: 1000px;
    background-image: url(https://img.xinshu.me/resource/deebffdf85a747cabc43adbbeee8d475);
    background-size: 100%;
    background-repeat: no-repeat;
    transition-timing-function: cubic-bezier(0.47, 0.12, 0.33, 1);
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &-inner {
      border-radius: 1000px;
      border: $theme-border;
      position: absolute;
      overflow: hidden;
      top: 6%;
      left: 6%;
      right: 6%;
      bottom: 6%;
    }
  }

  .trigger {
    cursor: pointer;
    border-radius: 1000px;
    width: 30%;
    height: 30%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15%;
    margin-top: -15%;
    background-color: $theme-yellow;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: $theme-border;
      border-radius: 1000px;
    }

    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: auto;
      margin-top: -3.6%;
    }

    .text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 2;
      width: 2.5em;
      margin: auto;
      line-height: 1.2;
      font-weight: bold;
      pointer-events: none;
      word-break: break-all;
      text-align: center;
    }
  }

  .prize {
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 100%;
    width: 50%;
    border-radius: 0 1000px 1000px 0;
    overflow: hidden;
    transform-origin: 0 50%;
    padding-bottom: 50%;

    &:before {
      content: '';
      display: block;
      border-left: $theme-border;
      left: 0;
      top: 0;
      height: 50%;
      position: absolute;
    }

    &:after {
      content: '';
      display: block;
      transform-origin: 100% 50%;
      transform: rotate(45deg);
      background-color: #fff;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: -100%;
      z-index: -1;
      pointer-events: none;
    }

    &:nth-child(2n+1):after {
      background-color: $theme-red;
    }

    .inner {
      text-align: center;
      margin-left: 2px;
      transform: rotate(22.5deg);
      transform-origin: top left;
      max-width: 77%;
      font-size: 12px;

      .title {
        margin-bottom: 5px;
      }

      .square {
        max-width: 40%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .count {
    padding: .25em 1em;
    border-radius: 4px;
    background-color: $theme-yellow;
    border: $theme-border;
    display: inline-block;
    cursor: pointer;
  }

  .prize-list {
    padding: .25em 1em;
    border-radius: 4px;
    background-color: $theme-blue;
    color: #fff;
    border: $theme-border;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
  }

  .btn {
    &[class*="theme-"] {
      padding: 4px 1em;

      &.btn-lg {
        padding-left: 2em;
        padding-right: 2em;
      }
    }

    &.theme-blue {
      background: $theme-blue;
      color: #fff;
      border-color: #000;
      border-width: 2px;
    }

    &.theme-yellow {
      background: $theme-yellow;
      border-color: #000;
      border-width: 2px;
    }
  }

  .text-theme-red {
    color: $theme-red;
  }

  .tab-content {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    hr {
      border-color: #000;
      border-width: 1px;
    }
  }

  h4 {
    line-height: 1.5;
  }
}
</style>
